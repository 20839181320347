import http from '@/utils/http';

/**
 * 系统菜单
 */
export default {
  getList: {
    p: 'get,/system/role/getlist',
    r: () => http({ url: '/system/role/getlist', method: 'get' })
  },
  // getOne: {
  //   p: 'post,/system/role/add',
  //   r: (data) => http({ url: '/system/role/add', method: 'post', data })
  // },
  add: {
    p: 'post,/system/role/add',
    r: (data) => http({ url: '/system/role/add', method: 'post', data })
  },
  update: {
    p: 'post,/system/role/update',
    r: (data) => http({ url: '/system/role/update', method: 'post', data })
  },
  del: {
    p: 'post,/system/role/delete',
    r: (data) => http({ url: '/system/role/delete', method: 'post', data })
  },
  getTree: {
    p: 'get,/system/role/handle_tree',
    r: (data) => http({ url: '/system/role/handle_tree', method: 'get', data })
  }
};
