import './list.scss';
import dataApi from '@/api/system/pages.js';
// import role from '@/api/system/role.js';
// import check from '@/api/workbench.js';

export default {
  name: 'account-list',
  components: {},
  data() {
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      searchData: {},
      tableData: [],
      tbleOption: {
        align: 'center',
        searchMenuPosition: 'left',
        filterBtn: false,
        delBtn: false,
        ...this.$store.getters.tableConfig,
        searchMenuSpan: 9,
        column: [
          {
            label: '场景值',
            type: 'select',
            span: 24,
            prop: 'scenario',
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            dicQuery: {
              dict_type: 'page'
            },
            required: true,
            rules: [
              {
                required: true,
                message: '请选择使用场景'
              }
            ]
          },
          {
            type: 'ueditor',
            component: 'AvueUeditor',
            label: '内容 ',
            span: 24,
            hide: true,
            rules: [
              {
                required: true,
                message: '请输入内容',
                trigger: 'blur'
              }
            ],
            prop: 'content'
          },
          {
            label: '状态',
            span: 12,
            rules: [
              {
                required: true,
                message: '请选择用户状态',
                trigger: 'blur'
              }
            ],
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: 0
              }
            ],
            prop: 'status'
          }
        ]
      },
      dialogVisible: false,
      account: {},
      tbleType: true
    };
  },
  computed: {},
  created() {

  },
  watch: {
  },
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      if (this.searchData.date_time) {
        this.searchData = {
          ...params,
          startdate: params.date_time[0],
          enddate: params.date_time[1]
          // date_type: 1
        };
      }
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    async handleSubmit(form, done, loading) {
      const postData = { ...form };
      console.log('postData', postData);
      dataApi.add.r(postData).then(() => {
        done(form);
        this.getList();
      });
      loading();
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 导出
    exportBtn() {
      this.$confirm('是否导出数据?', '提示', {
        confirmButtonText: '导出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.exportFile.r(this.searchData).then((res) => {
          console.log(res);
          this.$message.success('导出成功');
        });
      });
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        done(form);
        loading();
      });
      // done(form);
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      console.log('接口参数', postData);
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
